import React from 'react';

import { propTypes } from '../../util/types';
import { FormattedMessage } from '../../util/reactIntl';

import { Week } from '../../components';

import css from './ListingPage.module.css';

const ScheduleMaybe = (props) => {
  const {
    availabilityPlan,
  } = props;

  if (!availabilityPlan.entries) {
    return null;
  }

  return (
    <div className={css.sectionFeatures}>
      <h2 className={css.featuresTitle}>
        <FormattedMessage id="ListingPage.scheduleTitle" />
      </h2>
      <Week
        availabilityPlan={availabilityPlan}
      />
    </div>
  );
};

ScheduleMaybe.propTypes = {
  availabilityPlan: propTypes.availabilityPlan.isRequired,
};

export default ScheduleMaybe;
